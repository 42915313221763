define('fitmi-admin/components/sortable-supports', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    includedSupports: _ember['default'].computed('supports.@each.hasTags', 'hiddenSelectedBaseTags', function () {
      var includedSupports = this.get('supports').filterBy('matching', false);

      var hiddenSelectedBaseTags = this.get('hiddenSelectedBaseTags');
      var supportsWithTags = this.get('supports').filterBy('matching');
      supportsWithTags.forEach(function (support) {
        hiddenSelectedBaseTags.forEach(function (tag) {
          if (support.get('tags').includes(tag)) {
            includedSupports.addObject(support);
          }
        });
      });

      return includedSupports;
    }),
    filteredSupports: _ember['default'].computed('includedSupports', 'selectedSupportFilter', function () {
      var includedSupports = this.get('includedSupports');
      includedSupports = includedSupports.filterBy(this.get('selectedSupportFilter'));

      return includedSupports;
    }),
    selectedSupportFilter: 'drama'

  });
});
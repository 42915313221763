define('fitmi-admin/mixins/article', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    title: DS.attr('string'),
    tags: DS.hasMany('tag'),
    articleBases: DS.hasMany('article-base'),
    articleSupports: DS.hasMany('article-support'),

    tagEmotions: _ember['default'].computed('tags.@each.emotions', function () {
      var tags = this.get('tags');
      var tagsEmotions = [];
      for (var i = 0; i < tags.get('length'); i++) {
        var tagEmotions = tags.objectAt(i).get('emotions');
        for (var j = 0; j < tagEmotions.get('length'); j++) {
          tagsEmotions.push(tagEmotions.objectAt(j));
        }
      }

      return tagsEmotions;
    }),

    emotions: _ember['default'].computed.uniq('tagEmotions'),

    accuracy: _ember['default'].computed('articleSupports.@each.accuracy', 'articleBases.@each.accuracy', function () {
      return this.reduceBasedOn(this.get('articleSupports'), 'accuracy') + this.reduceBasedOn(this.get('articleBases'), 'accuracy') || 0;
    }),

    risk: _ember['default'].computed('articleSupports.@each.risk', 'articleBases.@each.risk', function () {
      return this.reduceBasedOn(this.get('articleSupports'), 'risk') + this.reduceBasedOn(this.get('articleBases'), 'risk') || 0;
    }),

    believability: _ember['default'].computed('articleSupports.@each.believability', 'articleBases.@each.believability', function () {
      return this.reduceBasedOn(this.get('articleSupports'), 'believability') + this.reduceBasedOn(this.get('articleBases'), 'believability') || 0;
    }),

    drama: _ember['default'].computed('tags.@each.drama', 'articleSupports.@each.drama', 'articleBases.@each.drama', function () {
      return this.reduceBasedOn(this.get('tags'), 'drama') + this.reduceBasedOn(this.get('articleSupports'), 'drama') + this.reduceBasedOn(this.get('articleBases'), 'drama') || 0;
    }),

    reduceBasedOn: function reduceBasedOn(enumerable, property) {
      return enumerable.reduce(function (acc, value) {
        return acc + value.get(property);
      }, 0);
    }

  });
});
define('fitmi-admin/controllers/emotions', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      saveItem: function saveItem(item) {
        item.save();
      },
      saveAllItems: function saveAllItems() {
        var items = this.get('model');
        items.forEach(function (item) {
          item.save();
        });
      },
      createNewItem: function createNewItem() {
        var emotionData = {
          name: "",
          heat: 5,
          direction: 0
        };
        var newEmotion = this.get('store').createRecord('emotion', emotionData);
        newEmotion.save();
      }
    }
  });
});
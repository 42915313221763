define('fitmi-admin/components/emotion-selector', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    emotions: _ember['default'].computed(function () {
      return this.get('store').findAll('emotion');
    }),
    remainingEmotions: null,
    unselectedEmotions: _ember['default'].computed.setDiff('emotions', 'targetObject.emotions'),
    actions: {
      addItem: function addItem(item) {
        this.get('targetObject.emotions').pushObject(item);
      },
      removeItem: function removeItem(item) {
        this.get('targetObject.emotions').removeObject(item);
      }
    }
  });
});
define('fitmi-admin/models/game', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    moment: Ember.inject.service(),

    revenue: _emberData['default'].attr('number', { defaultValue: 50 }),

    date: _emberData['default'].attr('date', {
      defaultValue: function defaultValue() {
        return new Date("January 1, 2016 00:00:00");
      }
    }),

    recurringExpense: _emberData['default'].attr({ defaultValue: [] }),

    expenseLog: _emberData['default'].attr({ defaultValue: [] }),

    hoursRemaining: Ember.computed('date', function () {
      return 8 - this.get('date').getHours();
    }),

    percentGoalComplete: Ember.computed('revenue', function () {
      return Math.floor(this.get('revenue') / this.get('gameGoal.cost') * 100);
    }),

    userName: _emberData['default'].attr('string'),
    guide: _emberData['default'].attr(),
    gameGoal: _emberData['default'].attr(),
    tutorialMode: _emberData['default'].attr('boolean'),

    hasHosting: _emberData['default'].attr('boolean', { defaultValue: false }),

    sites: _emberData['default'].hasMany('sites', { dependent: 'destroy' }),
    profiles: _emberData['default'].hasMany('user-profile', { dependent: 'destroy' }),

    trendingTags: _emberData['default'].hasMany('tag', { async: true }),

    canCollectPersonalInformation: _emberData['default'].attr('boolean', { defaultValue: false }),

    tutorialModel: _emberData['default'].attr('boolean', { defaultValue: true })

  });
});
define('fitmi-admin/router', ['exports', 'ember', 'fitmi-admin/config/environment'], function (exports, _ember, _fitmiAdminConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _fitmiAdminConfigEnvironment['default'].locationType,
    rootURL: _fitmiAdminConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('article', { path: '/article/:article_id' });
    this.route('base', { path: '/base/:article_base_id' });
    this.route('bases');

    this.route('articles', function () {
      this.route('new');
    });
    this.route('tags');
    this.route('profile', { path: '/profile/:profile_id' });
    this.route('profiles');
    this.route('groups', function () {
      this.route('new');
    });
    this.route('group', { path: '/group/:group_id' });
    this.route('emotions');

    this.route('supports');
    this.route('comments');
  });

  exports['default'] = Router;
});
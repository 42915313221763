define('fitmi-admin/models/group', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    gameData: Ember.inject.service(),

    title: _emberData['default'].attr('string'),
    totalNumbers: _emberData['default'].attr('number'),
    tags: _emberData['default'].hasMany('tag'),
    profiles: _emberData['default'].hasMany('profile'),

    politicalSlant: _emberData['default'].attr('')

    /*userPlants: DS.hasMany('user-plant'),
    userProfiles: DS.hasMany('user-profile', { inverse: 'pendingGroups' }),
    pendingMembers:DS.hasMany('user-profile', { inverse: 'pendingGroups' }),
     alreadyPlanted: Ember.computed('userPlants', function() {
      let plants = this.get('userPlants');
      let self = this;
      let alreadyPlanted = false;
       plants.forEach(function(plant) {
         if(plant.get('plantedBy.id') === self.get('gameData.currentProfile.id')) {
          if(plant.get('article.id') === self.get('gameData.currentArticle.id')) {
            alreadyPlanted = true;
          }
        }
      });
      return alreadyPlanted;
    })*/

  });
});
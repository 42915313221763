define('fitmi-admin/initializers/inflector', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = initialize;

  function initialize() /* application */{
    var inflector = _ember['default'].Inflector.inflector;
    inflector.irregular('base', 'bases');
    //inflector.singular(/base/, 'base');
  }

  exports['default'] = {
    name: 'inflector',
    initialize: initialize
  };
});
define("fitmi-admin/components/attribute-label", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    tagName: "span",
    classNames: ["ui", "label"],
    hasMax: _ember["default"].computed.notEmpty('max'),
    //classNameBindings: ["color"],
    color: _ember["default"].computed('value', function () {
      var ratio = this.get('value') / this.get('max');
      if (ratio < .3) {
        return "red";
      } else if (ratio < .5) {
        return "orange";
      } else if (ratio < .7) {
        return "olive";
      } else {
        return "green";
      }
    })
  });
});
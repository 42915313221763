define("fitmi-admin/components/emotion-view", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    classNames: ["ui", "label"],
    classNameBindings: ["color"],
    color: _ember["default"].computed('emotion.direction', function () {
      var direction = this.get('emotion.direction');
      if (direction === 0) {
        return "gray";
      } else if (direction === 1) {
        return "teal";
      } else if (direction === 2) {
        return "red";
      }
    })
  });
});
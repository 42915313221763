define('fitmi-admin/models/article-base', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    description: _emberData['default'].attr('string'),
    suggestion: _emberData['default'].attr('string'),
    category: _emberData['default'].attr('string'),

    believability: _emberData['default'].attr('number', { defaultValue: 0 }),
    accuracy: _emberData['default'].attr('number', { defaultValue: 0 }),
    drama: _emberData['default'].attr('number', { defaultValue: 0 }),
    risk: _emberData['default'].attr('number', { defaultValue: 0 }),

    articles: _emberData['default'].hasMany('article'),
    tags: _emberData['default'].hasMany('tag', { inverse: 'articleBases' }),
    hiddenTags: _emberData['default'].hasMany('tag', { inverse: 'hiddenArticleBases' })

    //userArticles: DS.hasMany('user-article')
  });
});
define('fitmi-admin/controllers/profile', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    imgSrc: _ember['default'].computed('model.photo', function () {
      return "../../images/" + this.get('model.photo');
    }),
    profileImages: ['person_1.png', 'person_2.png', 'person_3.png', 'person_4.png', 'person_5.png', 'person_6.png', 'person_7.png', 'person_8.png', 'person_9.png', 'person_10.png', 'person_11.png', 'person_12.png', 'person_13.png', 'person_14.png', 'person_15.png'],
    actions: {
      addTag: function addTag(tag) {
        this.get('model.tags').pushObject(tag);
      },
      removeTag: function removeTag(tag) {
        this.get('model.tags').removeObject(tag);
      },
      addGroup: function addGroup(group) {
        this.get('model.groups').pushObject(group);
      },
      removeGroup: function removeGroup(group) {
        this.get('model.groups').removeObject(group);
      },
      saveProfile: function saveProfile() {
        this.get('model').save();
        this.transitionToRoute('profiles');
      }
    }
  });
});
define('fitmi-admin/models/user-profile', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    game: _emberData['default'].belongsTo('game'),
    copiedFrom: _emberData['default'].belongsTo('profile'),

    name: _emberData['default'].attr('string'),
    photo: _emberData['default'].attr('string'),
    tags: _emberData['default'].hasMany('tag'),

    groups: _emberData['default'].hasMany('group', { inverse: 'userProfiles' }),
    pendingGroups: _emberData['default'].hasMany('group', { inverse: 'pendingMembers' }),

    credibility: _emberData['default'].attr('number'),
    friends: _emberData['default'].attr('number', { defaultValue: 0 }),

    saved: _emberData['default'].attr('boolean', { defaultValue: false }),

    userPlants: _emberData['default'].hasMany('user-plant')

  });
});
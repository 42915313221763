define('fitmi-admin/controllers/group', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      addTag: function addTag(tag) {
        this.get('model.tags').pushObject(tag);
      },
      removeTag: function removeTag(tag) {
        this.get('model.tags').removeObject(tag);
      },
      saveGroup: function saveGroup() {
        this.get('model').save();
        this.transitionToRoute('groups');
      }
    }
  });
});
define('fitmi-admin/routes/article', ['exports', 'ember', 'rsvp'], function (exports, _ember, _rsvp) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      return _rsvp['default'].hash({
        bases: this.get('store').findAll('article-base'),
        supports: this.get('store').findAll('article-support'),
        article: this.get('store').findRecord('article', params.article_id)
      });
    }
  });
});
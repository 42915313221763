define('fitmi-admin/models/site', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    name: attr('string'),
    logo: attr('string'),
    likes: attr('number', { defaultValue: 0 }),
    shares: attr('number', { defaultValue: 0 }),
    views: attr('number', { defaultValue: 0 }),
    revenue: attr('number', { defaultValue: 0 }),

    displaysAds: attr('boolean'),

    credibility: Ember.computed('templateCredibilityBonus', 'urlCredibilityBonus', 'truthinessCredibilityBonus', function () {
      return this.get('templateCredibilityBonus') + this.get('urlCredibilityBonus') + this.get('truthinessCredibilityBonus');
    }),

    templateCredibilityBonus: attr('number', { defaultValue: 0 }),
    urlCredibilityBonus: attr('number', { defaultValue: 0 }),

    truthinessCredibilityBonus: Ember.computed('totalArticles', function () {
      var bonus = 0;
      var articles = this.get('articles');

      if (articles.get('length') > 0) {
        var trueArticles = articles.map(function (article) {
          if (article.get('truthiness') > 3) {
            return true;
          } else return false;
        });

        var percentTrue = trueArticles.get('length') / articles.get('length');

        if (percentTrue > .6) {
          percentTrue = .6;
        };

        bonus = Math.floor(percentTrue * 50);
      }

      return bonus;
    }),

    plants: hasMany('user-plant', { async: true, dependent: 'destroy' }),

    totalArticles: Ember.computed('articles', function () {
      return this.get('articles.length');
    }),

    articles: hasMany('user-article', { async: true, dependent: 'destroy' }),

    game: belongsTo('game')
  });
});
define('fitmi-admin/controllers/tags', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    sortedTags: [],
    sortBy: 'name',
    reverseSort: true,
    sortDefinition: _ember['default'].computed(function () {
      var sortOrder = this.get('reverseSort') ? 'asc' : 'desc';
      return [this.get('sortBy') + ':' + sortOrder];
    }),

    actions: {
      sortItems: function sortItems() {
        var sortedTags = this.get('model').sortBy('topic', 'subject');
        this.set('sortedTags', sortedTags);
      },
      saveItem: function saveItem(item) {
        item.save();
      },
      saveAllItems: function saveAllItems() {
        var items = this.get('model');
        items.forEach(function (item) {
          item.save();
        });
      },
      changed: function changed() {
        console.log(this.get('model.emotions.length'));
      },
      addEmotion: function addEmotion(emotion) {
        this.get('emotions').pushObject(emotion);
      },
      removeEmotion: function removeEmotion(emotion) {
        this.get('emotions').removeObject(emotion);
      },
      destroyItem: function destroyItem(item) {
        item.destroyRecord();
      },
      createNewItem: function createNewItem() {
        var tagData = {
          name: "",
          drama: 5,
          party: 0
        };
        var newTag = this.get('store').createRecord('tag', tagData);
        newTag.save();
      }
    }
  });
});
define('fitmi-admin/models/user-plant', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    article: _emberData['default'].belongsTo('user-article'),
    plantedBy: _emberData['default'].belongsTo('user-profile'),
    plantedTo: _emberData['default'].belongsTo('group'),
    day: _emberData['default'].attr('number'),
    site: _emberData['default'].belongsTo('site'),
    hoursSincePlanted: _emberData['default'].attr('number'),
    createdAt: _emberData['default'].attr('date'),
    interest: _emberData['default'].attr('number'),

    success: _emberData['default'].attr('boolean'),

    active: _emberData['default'].attr('boolean'),

    tags: _emberData['default'].hasMany('tag'),

    politicalMatch: _emberData['default'].attr('boolean'),
    tagMatch: Ember.computed.notEmpty('tags'),
    emotionMatch: Ember.computed.notEmpty('emotions'),
    profileCredibilty: Ember.computed.gt('plantedBy.credibilty', 10),

    emotions: Ember.computed('tags', function () {
      var emotions = [];
      this.get('tags').mapBy('emotions').forEach(function (emotionArray) {
        emotionArray.forEach(function (emotion) {
          emotions.push(emotion);
        });
      });
      emotions = emotions.uniqBy('name');
      return emotions;
    }),

    groupMatchScore: _emberData['default'].attr('number'),
    profileMatchScore: _emberData['default'].attr('number'),
    trendingTagMatchScore: _emberData['default'].attr('number'),

    wasTrending: Ember.computed.gt('trendingTagMatchScore', 0),

    views: _emberData['default'].attr('number', { defaultValue: 0 }),
    likes: _emberData['default'].attr('number', { defaultValue: 0 }),
    shares: _emberData['default'].attr('number', { defaultValue: 0 }),
    revenue: _emberData['default'].attr('number', { defaultValue: 0 })

  });
});
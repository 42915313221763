define('fitmi-admin/controllers/articles/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    title: "",
    tags: [],
    actions: {
      addTag: function addTag(tag) {
        this.get('tags').pushObject(tag);
      },
      removeTag: function removeTag(tag) {
        this.get('tags').removeObject(tag);
      },
      saveArticle: function saveArticle() {
        var articleData = {
          title: this.get('title'),
          tags: this.get('tags')
        };

        var newArticle = this.get('store').createRecord('article', articleData);
        newArticle.save();
        this.transitionToRoute('articles');
      }
    }
  });
});
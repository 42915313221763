define("fitmi-admin/components/tag-view", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    classNames: ["ui", "tag", "label"],
    classNameBindings: ["color"],
    color: _ember["default"].computed('tag.party', function () {
      var party = parseInt(this.get('tag.party'));
      if (party === 0) {
        return "gray";
      } else if (party === 1) {
        return "orange";
      } else if (party === 2) {
        return "purple";
      }
    })
  });
});
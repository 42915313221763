define('fitmi-admin/controllers/base', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      addTag: function addTag(tag) {
        this.get('model.tags').pushObject(tag);
      },
      removeTag: function removeTag(tag) {
        this.get('model.tags').removeObject(tag);
      },
      saveItem: function saveItem() {
        this.get('model').save();
        this.transitionToRoute('bases');
      },
      saveAllItems: function saveAllItems() {
        var items = this.get('model');
        items.forEach(function (item) {
          item.save();
        });
        this.send('sortItems');
      }
    }
  });
});
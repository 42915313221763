define('fitmi-admin/components/write-article', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    notifications: _ember['default'].inject.service('notification-messages'),

    hasBases: _ember['default'].computed.gt('article.articleBases.length', 0),
    hasSupports: _ember['default'].computed.gt('article.articleSupports.length', 0),
    hasTitle: _ember['default'].computed.notEmpty('article.title'),

    readyToWrite: _ember['default'].computed.and('hasBases', 'hasSupports', 'hasTitle'),

    unaddedSupports: _ember['default'].computed.setDiff('supports', 'article.articleSupports'),
    unselectedBases: _ember['default'].computed.setDiff('bases', 'article.articleBases'),

    hoursRequired: _ember['default'].computed('article.articleSupports', 'article.articleBases', function () {
      return this.get('article.articleSupports.length') + this.get('article.articleBases.length') * 2;
    }),

    selectedSupportTags: _ember['default'].computed('hasSupports', 'article.articleSupports.@each.tags', function () {
      var tags = [];
      if (this.get('hasSupports')) {
        this.get('article.articleSupports').forEach(function (support) {
          support.get('tags').forEach(function (tag) {
            tags.pushObject(tag);
          });
        });
      }
      return tags;
    }),

    selectedBaseTags: _ember['default'].computed('hasBases', 'article.articleBases.@each.tags', function () {
      var tags = [];
      if (this.get('hasBases')) {
        this.get('article.articleBases').forEach(function (base) {
          base.get('tags').forEach(function (tag) {
            tags.pushObject(tag);
          });
        });
      }
      return tags;
    }),

    hiddenSelectedBaseTags: _ember['default'].computed('hasBases', 'article.articleBases.@each.hiddenTags', function () {
      var tags = [];
      this.get('article.articleBases').forEach(function (base) {
        base.get('hiddenTags').forEach(function (tag) {
          tags.pushObject(tag);
        });
      });
      return tags;
    }),

    matchingTags: _ember['default'].computed.intersect('selectedSupportTags', 'hiddenSelectedBaseTags'),

    uniqueTags: _ember['default'].computed.uniq('selectedBaseTags', 'matchingTags'),

    // After this - if two tags have the same category, then the category with the highest drama should stay

    revealedTags: _ember['default'].computed('article', 'uniqueTags', 'article.articleSupports', function () {
      var uniqueTags = this.get('uniqueTags');
      // Add in the non-matching type tags
      var selectedSupports = this.get('article.articleSupports').filterBy('matching', false);
      selectedSupports.forEach(function (support) {
        var tags = support.get('tags');
        tags.forEach(function (tag) {
          uniqueTags.addObject(tag);
        });
      });
      var uniqueCategories = uniqueTags.mapBy('subject').uniq();
      var revealedTags = uniqueTags;

      if (!(uniqueTags.get('length') === uniqueCategories.get('length'))) {
        var highestDramaTags = [];
        for (var i = 0; i < uniqueCategories.get('length'); i++) {
          var categoryTags = uniqueTags.filterBy('subject', uniqueCategories[i]);
          var sortedByDramaTags = categoryTags.sortBy('drama');
          highestDramaTags.push(sortedByDramaTags.slice(-1)[0]);
        }
        revealedTags = highestDramaTags;
      }
      if (_ember['default'].isPresent(this.get('article'))) {
        this.set('article.tags', revealedTags);
      }
      return revealedTags;
    }),

    store: _ember['default'].inject.service(),

    //article: null,
    /*didReceiveAttrs() {
      if (Ember.isBlank(this.get('article'))) {
        console.log('created new article')
        let article = this.get('store').createRecord('article');
        this.set('article', article);
      }
    },*/

    maxBases: 1,
    maxSupports: 5,

    actions: {
      addBase: function addBase(base) {
        var bases = this.get('article.articleBases');
        if (bases.get('length') < this.get('maxBases')) {
          bases.pushObject(base);
          if (bases.get('length') === this.get('maxBases')) {
            $('.ui.accordion').accordion('open', 1);
          }
        } else {
          this.get('notifications').warning('You can only have one article base. If you want to add a different base, first remove the base on the right. ', {
            autoClear: true,
            clearDuration: 3600
          });
        }
      },
      removeBase: function removeBase(base) {
        this.get('article.articleBases').removeObject(base);
        $('.ui.accordion').accordion('open', 0);
      },
      doneWithSupports: function doneWithSupports() {
        $('.ui.accordion').accordion('open', 2);
      },

      doneWithTitle: function doneWithTitle() {
        $('.ui.accordion').accordion('open', 3);
      },

      addSupport: function addSupport(support) {
        var supports = this.get('article.articleSupports');
        if (supports.get('length') < this.get('maxSupports')) {
          supports.pushObject(support);
          if (supports.get('length') === this.get('maxSupports')) {
            $('.ui.accordion').accordion('open', 2);
          }
        } else {
          this.get('notifications').warning('You have already added the maximum number of supports. If you want to add a different support, first remove one of the supports on the right.', {
            autoClear: true,
            clearDuration: 3600
          });
        }
      },
      removeSupport: function removeSupport(support) {
        this.get('article.articleSupports').removeObject(support);
        $('.ui.accordion').accordion('open', 1);
      },

      saveArticle: function saveArticle() {
        var article = this.get('article');
        article.setProperties({
          tags: this.get('revealedTags')
        });

        article.save();
        this.sendAction('articleComplete');
      }
    }
  });
});
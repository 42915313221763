define('fitmi-admin/controllers/articles', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      createNewArticle: function createNewArticle() {
        var newArticle = this.get('store').createRecord('article');
        var self = this;
        newArticle.save().then(function (newArticle) {
          self.transitionToRoute('article', newArticle.id);
        });
      },
      saveItem: function saveItem(item) {
        item.save();
      },
      saveAllItems: function saveAllItems() {
        var items = this.get('model');
        items.forEach(function (item) {
          item.save();
        });
      }
    }
  });
});
define('fitmi-admin/mixins/retrieve-nested', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  exports['default'] = _ember['default'].Mixin.create({
    retrieveNestedTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(parentSet, nestedType) {
      var nestedSet, i, fromOne, j;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            nestedSet = [];
            i = 0;

          case 2:
            if (!(i < parentSet.get('length'))) {
              context$1$0.next = 10;
              break;
            }

            context$1$0.next = 5;
            return parentSet.objectAt(i).get(nestedType);

          case 5:
            fromOne = context$1$0.sent;

            for (j = 0; j < fromOne.get('length'); j++) {
              nestedSet.push(fromOne.objectAt(j));
            }

          case 7:
            i++;
            context$1$0.next = 2;
            break;

          case 10:
            return context$1$0.abrupt('return', nestedSet);

          case 11:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    retrieveNested: function retrieveNested(parentSet, nestedType) {
      var nestedSet = [];
      for (var i = 0; i < parentSet.get('length'); i++) {
        var fromOne = parentSet.objectAt(i).get(nestedType);
        for (var j = 0; j < fromOne.get('length'); j++) {
          nestedSet.push(fromOne.objectAt(j));
        }
      }
      return nestedSet;
    }
  });
});
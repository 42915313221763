define('fitmi-admin/controllers/article', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      addSupport: function addSupport(support) {
        this.get('model.articleSupports').pushObject(support);
      },
      removeSupport: function removeSupport(support) {
        this.get('model.articleSupports').removeObject(support);
      },
      saveArticle: function saveArticle() {
        this.get('model').save();
        this.transitionToRoute('articles');
      },
      deleteArticle: function deleteArticle() {
        this.transitionToRoute('articles');
        this.get('model').destroyRecord();
      }
    }
  });
});
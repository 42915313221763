define('fitmi-admin/controllers/groups/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    title: "",
    tags: [],
    actions: {
      addTag: function addTag(tag) {
        this.get('tags').pushObject(tag);
      },
      removeTag: function removeTag(tag) {
        this.get('tags').removeObject(tag);
      },
      saveGroup: function saveGroup() {
        var groupData = {
          title: this.get('title'),
          tags: this.get('tags'),
          totalNumbers: 0,
          profiles: []
        };

        var newGroup = this.get('store').createRecord('group', groupData);
        newGroup.save();
        this.transitionToRoute('groups');
      }
    }
  });
});
define('fitmi-admin/models/user-article', ['exports', 'ember-data', 'fitmi-admin/mixins/article'], function (exports, _emberData, _fitmiAdminMixinsArticle) {
  exports['default'] = _emberData['default'].Model.extend(_fitmiAdminMixinsArticle['default'], {
    copiedFrom: _emberData['default'].belongsTo('article'),
    site: _emberData['default'].belongsTo('site', { autoSave: true }),

    //affected by plants
    active: _emberData['default'].attr('boolean', { defaultValue: false }),
    userPlants: _emberData['default'].hasMany('user-plant'),

    views: _emberData['default'].attr('number', { defaultValue: 0 }),
    likes: _emberData['default'].attr('number', { defaultValue: 0 }),
    shares: _emberData['default'].attr('number', { defaultValue: 0 }),
    revenue: _emberData['default'].attr('number', { defaultValue: 0 }),
    daysOld: _emberData['default'].attr('number', { defaultValue: 0 }),

    hoursSinceLastShare: _emberData['default'].attr('number', { defaultValue: 0 }),

    saved: ('boolean', { defaultValue: false })
  });
});
define('fitmi-admin/controllers/groups', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      saveItem: function saveItem(item) {
        item.save();
      },
      saveAllItems: function saveAllItems() {
        var items = this.get('model');
        items.forEach(function (item) {
          item.save();
        });
      }
    }
  });
});
define("fitmi-admin/templates/profile", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 6
              },
              "end": {
                "line": 14,
                "column": 6
              }
            },
            "moduleName": "fitmi-admin/templates/profile.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "item");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element1, 'data-value');
            morphs[1] = dom.createAttrMorph(element2, 'src');
            return morphs;
          },
          statements: [["attribute", "data-value", ["concat", [["get", "image", ["loc", [null, [11, 40], [11, 45]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["subexpr", "concat", ["../../images/", ["get", "image", ["loc", [null, [12, 44], [12, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [12, 51]]], 0, 0], 0, 0, 0, 0]],
          locals: ["image"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 2
            },
            "end": {
              "line": 16,
              "column": 2
            }
          },
          "moduleName": "fitmi-admin/templates/profile.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "dropdown icon");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "default text");
          var el2 = dom.createTextNode("Select Image");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "menu");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [5]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "profileImages", ["loc", [null, [10, 14], [10, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [10, 6], [14, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 4
            },
            "end": {
              "line": 25,
              "column": 4
            }
          },
          "moduleName": "fitmi-admin/templates/profile.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "ui label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["element", "action", ["removeGroup", ["get", "group", ["loc", [null, [24, 51], [24, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 28], [24, 58]]], 0, 0], ["content", "group.title", ["loc", [null, [24, 59], [24, 74]]], 0, 0, 0, 0]],
        locals: ["group"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 36,
            "column": 0
          }
        },
        "moduleName": "fitmi-admin/templates/profile.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ui container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("img");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Political Slant: ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Credibility: ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Friends: ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "ui basic teal button");
        var el3 = dom.createTextNode("Save");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [5]);
        var element5 = dom.childAt(element3, [19]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(element3, 1, 1);
        morphs[1] = dom.createAttrMorph(element4, 'src');
        morphs[2] = dom.createMorphAt(element3, 7, 7);
        morphs[3] = dom.createMorphAt(dom.childAt(element3, [9]), 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element3, [11]), 1, 1);
        morphs[5] = dom.createMorphAt(dom.childAt(element3, [13]), 1, 1);
        morphs[6] = dom.createMorphAt(dom.childAt(element3, [15]), 1, 1);
        morphs[7] = dom.createElementMorph(element5);
        morphs[8] = dom.createMorphAt(element3, 23, 23);
        return morphs;
      },
      statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "model.name", ["loc", [null, [2, 16], [2, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "80"], ["loc", [null, [2, 2], [2, 38]]], 0, 0], ["attribute", "src", ["get", "imgSrc", ["loc", [null, [4, 13], [4, 19]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "ui-dropdown", [], ["class", "selection", "selected", ["subexpr", "@mut", [["get", "model.photo", ["loc", [null, [6, 44], [6, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.photo", ["loc", [null, [6, 78], [6, 89]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 73], [6, 90]]], 0, 0]], [], ["loc", [null, [6, 65], [6, 91]]], 0, 0]], 0, null, ["loc", [null, [6, 2], [16, 18]]]], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "model.politicalSlant", ["loc", [null, [18, 34], [18, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "1"], ["loc", [null, [18, 20], [18, 65]]], 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "model.credibility", ["loc", [null, [19, 30], [19, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "2"], ["loc", [null, [19, 16], [19, 58]]], 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "model.friends", ["loc", [null, [20, 26], [20, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "2"], ["loc", [null, [20, 12], [20, 50]]], 0, 0], ["block", "each", [["get", "model.groups", ["loc", [null, [23, 12], [23, 24]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [23, 4], [25, 13]]]], ["element", "action", ["saveProfile"], [], ["loc", [null, [28, 39], [28, 63]]], 0, 0], ["inline", "available-groups", [], ["groupSelected", ["subexpr", "action", ["addGroup"], [], ["loc", [null, [31, 35], [31, 54]]], 0, 0]], ["loc", [null, [31, 2], [31, 56]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});
define('fitmi-admin/models/tag', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    drama: _emberData['default'].attr('number', { defaultValue: 0 }),
    party: _emberData['default'].attr('number'),
    topic: _emberData['default'].attr('string'),
    subject: _emberData['default'].attr('string'),
    slant: _emberData['default'].attr('string'),

    articles: _emberData['default'].hasMany('article'),
    profiles: _emberData['default'].hasMany('profile'),
    groups: _emberData['default'].hasMany('group'),

    emotions: _emberData['default'].hasMany('emotion'),

    articleBases: _emberData['default'].hasMany('article-base'),
    hiddenArticleBases: _emberData['default'].hasMany('article-base')

  });
});
//userArticles: DS.hasMany('user-article'),
//userProfiles: DS.hasMany('user-profile'),
//userPlants: DS.hasMany('user-plant'),

//games: DS.hasMany('game')
define('fitmi-admin/components/tag-selector', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    tags: _ember['default'].computed(function () {
      return this.get('store').findAll('tag');
    }),
    tagCategories: _ember['default'].computed('tags.@each.topic', function () {
      var tags = this.get('tags');
      var tagCategories = [];

      var categories = tags.mapBy('topic').uniq();

      categories.forEach(function (topic) {
        var tagSet = tags.filterBy('topic', topic);
        var topicObject = {
          title: topic,
          tags: tagSet
        };
        tagCategories.push(topicObject);
      });

      return tagCategories;
    }),
    remainingTags: null,
    unselectedTags: _ember['default'].computed.setDiff('tags', 'targetObject.tags'),
    hasMaxTags: _ember['default'].isPresent('maxTags'),
    errorMessage: null,
    hasErrorMessage: _ember['default'].isPresent('errorMessage'),
    targetObjectTags: _ember['default'].computed('targetObject', 'hidden', function () {
      if (this.get('hidden') === true) {
        return this.get('targetObject.hiddenTags');
      } else {
        return this.get('targetObject.tags');
      }
    }),
    actions: {
      addTag: function addTag(tag) {
        if (this.get('hasMaxTags')) {
          if (this.get('maxTags') > this.get('targetObjectTags.length')) {
            this.get('targetObjectTags').pushObject(tag);
            this.set('errorMessage', null);
          } else {
            this.set('errorMessage', "You can add a maximum of " + this.get('maxTags') + " tags");
          }
        } else {
          this.get('targetObjectTags').pushObject(tag);
          this.set('errorMessage', null);
        }
      },
      removeTag: function removeTag(tag) {
        this.get('targetObjectTags').removeObject(tag);
        this.set('errorMessage', null);
      }
    }

  });
});
define('fitmi-admin/models/profile', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr(),
    photo: _emberData['default'].attr(),
    tags: _emberData['default'].hasMany('tag'),
    groups: _emberData['default'].hasMany('group'),
    politicalSlant: _emberData['default'].attr('number'),

    credibility: _emberData['default'].attr('number'),
    friends: _emberData['default'].attr('number')
  });
});